import { Add, NotesSharp } from "@material-ui/icons";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { CompanyStructureModal, FormInputField, FormSelectInput } from ".";
import React, { useState } from "react";

//Material UI
import { Alert } from "@material-ui/lab";
import DateRangeInput from "./DateRangePicker";
import EmployeeVacationTable from "./EmployeeVacationTable";
//Components
import SectionTitle from "./SectionTitle";
import { connect, useSelector } from "react-redux";
import { createLeaveRequest } from "../../redux/actions";
import { leavesKind } from "./DropDownsData";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

const EmployeeVacationRequest = ({
  minHeight = "400px",
  titleChart,
  title,
  children,
  data,
  createLeaveRequest,
  user,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState([new Date(), new Date()]);

  const [leave, setLeave] = useState({
    date: moment(new Date()).format("yyyy-MM-DD"),
    kind: "ANNUAL",
    reason: "",
    endDate: moment(new Date()).format("yyyy-MM-DD"),
  });

  const [error, setError] = useState("");
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const isEnglish = language === "en";
  const companyVacationTypes = useSelector(
    (state) => state.company.vacationTypes,
  );
  return (
    <>
      <Grid item md={11} xs={12}>
        <Box
          clone
          bgcolor="white"
          minHeight={minHeight}
          paddingX={matchesMD ? 2 : 6}
          marginBottom={3}
          paddingY={3}
          borderRadius={10}
        >
          <Grid container justifyContent="center">
            <Grid item md={12} xs={12}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <SectionTitle
                  title={titleChart}
                  icon={<NotesSharp color="primary" />}
                />
                {user.isEmployee && (
                  <Button
                    style={{ width: "195px" }}
                    variant="text"
                    color="primary"
                    endIcon={<Add />}
                    onClick={() => setOpen(true)}
                  >
                    {t("leaveRequest")}
                  </Button>
                )}
              </Box>
            </Grid>
            <Grid item md={12} xs={12} style={{ maxWidth: "100vh" }}>
              {children}
            </Grid>

            <SectionTitle title={title} icon={<NotesSharp color="primary" />} />
            <EmployeeVacationTable data={data} />
          </Grid>
        </Box>
      </Grid>
      <CompanyStructureModal
        open={open}
        onClose={() => setOpen(false)}
        fadeIn={open}
        title={t("leaveRequest")}
        height="400px"
      >
        <Grid container spacing={3} justifyContent="center">
          {error && (
            <Grid item md={8} xs={12}>
              <Alert onClick={() => setError("")} severity="error">
                {error}
              </Alert>
            </Grid>
          )}
          <Grid item md={6} xs={6}>
            <FormInputField
              isRequired
              label={t("reason")}
              name="reason"
              type="text"
              onChange={(e) => setLeave({ ...leave, reason: e.target.value })}
              value={leave.reason}
              id="reason"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <FormSelectInput
              isRequired
              label={t("leaveKind")}
              name="kind"
              selectOptions={leavesKind
                .filter(
                  (item) =>
                    companyVacationTypes.includes(item.value) ||
                    item.value === "UNPAID",
                )
                .map((kind) => ({
                  label: isEnglish ? kind.name : kind.nameAr,
                  value: kind.value,
                }))}
              onChange={(e) => setLeave({ ...leave, kind: e })}
              value={leave.kind}
            />
          </Grid>
          <Grid item md={12} xs={6}>
            <DateRangeInput
              onChange={(selectedDates) => {
                setDates(selectedDates);
                setLeave({
                  ...leave,
                  date: moment(selectedDates[0]).format("yyyy-MM-DD"),
                  endDate: moment(selectedDates[1]).format("yyyy-MM-DD"),
                });
              }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Box clone marginX={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (leave.reason === "") {
                      setError(t("reasonError"));
                    } else {
                      createLeaveRequest(leave, () => setOpen(false));
                    }
                  }}
                >
                  <Typography>{t("confirm")}</Typography>
                </Button>
              </Box>
              <Box clone marginX={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpen(false)}
                >
                  <Typography>{t("cancel")}</Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CompanyStructureModal>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = {
  createLeaveRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeVacationRequest);
