//Material UI
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import {
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
//Moment
import moment from "moment";
import React, { useEffect, useState } from "react";
//Translation
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { monthsEmployed } from "../../../../../helpers/monthsEmployed";
import {
  createLeaveRequest,
  fetchEmployeeLeaveSalary,
} from "../../../../../redux/actions";
//Components
import {
  CompanyStructureModal,
  DatePickerField,
  FormInputField,
  FormSelectInput,
} from "../../../../common";
import { leavesKind } from "../../../../common/DropDownsData";
import LeaveFile from "../../../../common/LeaveFile";
import TimePickerField from "../../../../common/TimePickerField";

const LeaveRequestModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const employeeLeaveSalary = useSelector(
    (state) => state.employee.leaveSalary,
  );
  const addRequestLoading = useSelector(
    (state) => state.employee.addRequestLoading,
  );
  const employee = useSelector((state) => state.employee.profile);
  const [value, setValue] = useState("before");

  const [leave, setLeave] = useState({
    date: moment(new Date()).format("yyyy-MM-DD"),
    kind: "ANNUAL",
    reason: "",
    endDate: moment(new Date()).format("yyyy-MM-DD"),
    document: "",
    startTime: moment(new Date()),
    endTime: moment(new Date()),
    requestSalaryBeforeLeave: value === "before" ? true : false,
  });

  const [error, setError] = useState("");

  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";
  const companyVacationTypes = useSelector(
    (state) => state.employee.vacationTypes,
  );
  useEffect(() => {
    dispatch(
      fetchEmployeeLeaveSalary({
        startDate: leave.date,
        endDate: leave.endDate,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    leave.kind,
    leave.date,
    leave.endDate,
    leave.requestSalaryBeforeLeave,
  ]);

  useEffect(() => {
    const reqValue = value === "before" ? true : false;

    setLeave({ ...leave, requestSalaryBeforeLeave: reqValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (!employee) return <></>;

  let availableBalance = employee.leaveBalance.annualBalance;
  let casualBalance = employee.leaveBalance.casualBalance;
  let sickBalance = employee.leaveBalance.sickBalance;

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const getLeaveSalary = () => {
    switch (leave.kind) {
      case "ANNUAL":
        return employeeLeaveSalary?.annualBalance;

      case "CASUAL":
        return employeeLeaveSalary?.casualBalance;

      case "SICK":
        return employeeLeaveSalary?.sickBalance;

      case "SHORT":
        return employeeLeaveSalary?.shortLeaveBalance;

      case "UNPAID":
        return 0;

      default:
        break;
    }
  };

  const getAvailableBalance = () => {
    switch (leave.kind) {
      case "ANNUAL":
        return availableBalance;

      case "CASUAL":
        return casualBalance;

      case "SICK":
        return sickBalance;

      default:
        return "-";
    }
  };

  const filteredLeaveTypes = () => {
    const totalMonths = monthsEmployed(employee.employmentDate, leave.date);
    const modifiedLeaveTypes = leavesKind.filter(
      (leave) => leave.value !== "SICK",
    );

    if (employee.employmentStatus === "FULL_TIME" && totalMonths < 6) {
      if (leave.kind === "SICK") {
        setLeave((leave) => ({ ...leave, kind: "UNPAID" }));
      }
      return modifiedLeaveTypes;
    } else if (
      employee.employmentStatus === "TRIAL_PERIOD" &&
      totalMonths < 3
    ) {
      if (leave.kind === "SICK") {
        setLeave((leave) => ({ ...leave, kind: "UNPAID" }));
      }

      return modifiedLeaveTypes;
    } else {
      return leavesKind;
    }
  };

  const closeModal = () => {
    setOpen(false);
    setError("");
    setValue("before");
    setLeave({
      date: moment(new Date()).format("yyyy-MM-DD"),
      kind: "ANNUAL",
      reason: "",
      endDate: moment(new Date()).format("yyyy-MM-DD"),
      startTime: moment(new Date()),
      endTime: moment(new Date()),
      requestSalaryBeforeLeave: value === "before" ? true : false,
    });
  };

  const onSubmit = () => {
    if (leave.reason === "") {
      setError(t("reasonError"));
    } else {
      if (leave.kind !== "SHORT") {
        const { startTime, endTime, ...rest } = leave;
        dispatch(createLeaveRequest(rest, () => closeModal()));
      } else {
        const { startTime, endTime, requestSalaryBeforeLeave, ...rest } = leave;
        if (moment(endTime).isBefore(moment(startTime))) {
          setError(t("timeError"));
        } else {
          dispatch(
            createLeaveRequest(
              {
                startTime: startTime.format("HH:mm:ss"),
                endTime: endTime.format("HH:mm:ss"),
                ...rest,
              },
              () => closeModal(),
            ),
          );
        }
      }
    }
  };

  return (
    open && (
      <CompanyStructureModal
        open={open}
        onClose={closeModal}
        fadeIn={open}
        title={t("leaveRequest")}
      >
        <Box height={"auto"} maxHeight={"90vh"} margin={4}>
          {addRequestLoading ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={40} />
            </Box>
          ) : (
            <>
              <Grid container spacing={3} justifyContent="center">
                {error && (
                  <Grid item md={8} xs={12}>
                    <Alert onClick={() => setError("")} severity="error">
                      {error}
                    </Alert>
                  </Grid>
                )}
                <Grid item md={6} xs={6}>
                  <FormInputField
                    isRequired
                    label={t("reason")}
                    name="reason"
                    type="text"
                    onChange={(e) => {
                      setLeave({ ...leave, reason: e.target.value });
                    }}
                    value={leave.reason}
                    id="reason"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <FormSelectInput
                    isRequired
                    label={t("leaveKind")}
                    name="kind"
                    selectOptions={filteredLeaveTypes()
                      .filter(
                        (item) =>
                          companyVacationTypes.includes(item.value) ||
                          item.value === "UNPAID",
                      )
                      .map((kind) => ({
                        label: isEnglish ? kind.name : kind.nameAr,
                        value: kind.value,
                      }))}
                    onChange={(e) => setLeave({ ...leave, kind: e })}
                    value={leave.kind}
                  />
                </Grid>

                {leave.kind === "SHORT" ? (
                  <>
                    <Grid item md={12} xs={6}>
                      <DatePickerField
                        label={t("date")}
                        name="date"
                        onChange={(e) =>
                          setLeave({
                            ...leave,
                            date: moment(e).format("yyyy-MM-DD"),
                            endDate: moment(e).format("yyyy-MM-DD"),
                          })
                        }
                        value={leave.date}
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <TimePickerField
                        label={t("startTime")}
                        name="startTime"
                        onChange={(e) =>
                          setLeave({
                            ...leave,
                            startTime: moment(e),
                          })
                        }
                        value={leave.startTime}
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <TimePickerField
                        label={t("endTime")}
                        name="endTime"
                        onChange={(e) =>
                          setLeave({
                            ...leave,
                            endTime: moment(e),
                          })
                        }
                        value={leave.endTime}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item md={6} xs={12}>
                      {/* <DateRangeInput
                      onChange={(selectedDates) =>
                        setLeave({
                          ...leave,
                          date: moment(selectedDates[0]).format("yyyy-MM-DD"),
                          endDate: moment(selectedDates[1]).format(
                            "yyyy-MM-DD",
                          ),
                        })
                      }
                    /> */}
                      <DatePickerField
                        label={t("date")}
                        name="date"
                        onChange={(e) =>
                          setLeave({
                            ...leave,
                            date: moment(e).format("yyyy-MM-DD"),
                          })
                        }
                        value={leave.date}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <DatePickerField
                        label={t("endDate")}
                        name="endDate"
                        onChange={(e) =>
                          setLeave({
                            ...leave,
                            endDate: moment(e).format("yyyy-MM-DD"),
                          })
                        }
                        value={leave.endDate}
                      />
                    </Grid>
                  </>
                )}

                <Grid item md={12}>
                  <LeaveFile
                    value={leave.document}
                    setValue={(newDocument) =>
                      setLeave({ ...leave, document: newDocument })
                    }
                  />
                </Grid>
                <Grid item container>
                  <Grid item md={12} style={{ paddingTop: 1 }}>
                    <Typography variant="subtitle2" color="textSecondary">{`${t(
                      "common:totalCountRequested",
                    )}: ${
                      moment(leave.endDate, "yyyy-MM-DD")?.diff(
                        moment(leave.date, "yyyy-MM-DD"),
                        "days",
                      ) + 1
                    }`}</Typography>
                  </Grid>

                  <Grid item md={12} style={{ paddingTop: 1 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {" "}
                      {`${t("common:estimatedSalaryAfterLeave")}: ${parseFloat(
                        getLeaveSalary(),
                      ).toFixed(3)} KWD`}
                    </Typography>
                  </Grid>

                  <Grid item md={12} style={{ paddingTop: 1 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {" "}
                      {`${t(
                        "common:totalNumberInBalance",
                      )}:  ${getAvailableBalance()}`}
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="salaryRequest"
                        value={value}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={"before"}
                          control={
                            <Radio
                              size="small"
                              checkedIcon={<CheckBoxOutlined />}
                              icon={<CheckBoxOutlineBlankOutlined />}
                            />
                          }
                          label={
                            <Typography
                              variant="subtitle2"
                              color="primary"
                              style={{ fontWeight: "bold" }}
                            >
                              {t("common:requestSalaryBeforeLeave")}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={"after"}
                          control={
                            <Radio
                              size="small"
                              checkedIcon={<CheckBoxOutlined />}
                              icon={<CheckBoxOutlineBlankOutlined />}
                            />
                          }
                          label={
                            <Typography
                              variant="subtitle2"
                              color="primary"
                              style={{ fontWeight: "bold" }}
                            >
                              {t("common:requestSalaryAfterLeave")}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item md={12} xs={12}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    <Box clone marginX={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                      >
                        <Typography>{t("confirm")}</Typography>
                      </Button>
                    </Box>
                    <Box clone marginX={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setOpen(false)}
                      >
                        <Typography>{t("cancel")}</Typography>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </CompanyStructureModal>
    )
  );
};

export default LeaveRequestModal;
