import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
//Moment
import moment from "moment";
import React, { useState } from "react";
//Translation
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";

import { isLeaveRequestApproved } from "../../../helpers/isLeaveRequestApproved";
//Actions
import { amendLeaveRequest, updateLeaveRequest } from "../../../redux/actions";
import {
  CompanyStructureModal,
  DatePickerField,
  FormInputField,
  FormSelectInput,
  LoadingButton,
  Section,
} from "../../common";
import { leavesKind } from "../../common/DropDownsData";
import LeaveFile from "../../common/LeaveFile";
import MultilineInput from "../../common/MultilineInput";
import TimePickerField from "../../common/TimePickerField";
import Field from "./Field";

const Leave = ({
  approversStatus,
  leave: _leave,
  request,
  updateLeaveRequest,
  user,
  employeeProfile,
  requests,
  getBalance,
  updateRequestLoading,
  amendLeaveRequest,
}) => {
  let myApproverStatus = user.isCompanyOwner
    ? null
    : approversStatus.find(
        (status) => status?.approvedBy?.employee?.id === employeeProfile?.id,
      );

  const {
    t,
    i18n: { language },
  } = useTranslation("requests");
  const isEnglish = language === "en";

  const [leave, setLeave] = useState(_leave);

  const [error, setError] = useState("");

  const [open, setOpen] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [notes, setNotes] = useState(" ");

  const isShortLeave = leave.kind === "SHORT";
  const companyVacationTypes = useSelector(
    (state) => state.company.vacationTypes,
  );

  const vacationTypes = useSelector((state) => state.employee.vacationTypes);

  let kind = leavesKind.find((kindObj) => kindObj.value === leave.kind);

  const hasVacationsAccess = employeeProfile?.user.hasVacationsAccess;
  const employeeWithHighestPriority = request.isLastApprover;

  const hasHrAccess =
    user.isEmployee && employeeProfile && employeeProfile.hrAccess;

  const isOverLapping = (req) => {
    // Current Leave that is being viewed
    const leaveStartDate = moment(leave.date, "YYYY-MM-DD");
    const leaveEndDate = moment(leave.endDate, "YYYY-MM-DD");

    // Leave Request that is being mapped over
    const requestStartDate = moment(req.leaveRequest.date, "YYYY-MM-DD");
    const requestEndDate = moment(req.leaveRequest.endDate, "YYYY-MM-DD");

    // Request Start Date is before or same Leave Start Date
    // Request End Date is after or same Leave End Date
    // Ex. Request -> Nov 11 - Nov 18
    //     Leave -> Nov 13 - Nov 15
    const conditionOne =
      requestStartDate.isSameOrBefore(leaveStartDate) &&
      requestEndDate.isSameOrAfter(leaveEndDate);

    // Request Start Date is before or same Leave Start Date
    // Request End Date is beofre or same Leave End Date
    // Ex. Request -> Nov 11 - Nov 15
    //     Leave -> Nov 12 - Nov 17
    const conditionTwo =
      requestStartDate.isSameOrBefore(leaveStartDate) &&
      requestEndDate.isSameOrBefore(leaveEndDate) &&
      requestEndDate.isSameOrAfter(leaveStartDate);

    // Request Start Date is after or same Leave Start Date
    // Request End Date is before or same Leave End Date
    // Ex. Request -> Nov 11 - Nov 14
    //     Leave -> Nov 9 - Nov 15
    const conditionThree =
      requestStartDate.isSameOrAfter(leaveStartDate) &&
      requestEndDate.isSameOrBefore(leaveEndDate);

    // Request Start Date is after or same Leave Start Date
    // Request End Date is after or same Leave End Date
    // Ex. Request -> Nov 15 - Nov 19
    //     Leave -> Nov 12 - Nov 17
    const conditionFour =
      requestStartDate.isSameOrAfter(leaveStartDate) &&
      requestEndDate.isSameOrAfter(leaveEndDate) &&
      requestStartDate.isSameOrBefore(leaveEndDate);

    return conditionOne || conditionTwo || conditionThree || conditionFour
      ? true
      : false;
  };

  const getEmployeesOnVacation = () => {
    return requests
      .filter(
        (req) => req.employee.department.id === request.employee.department.id,
      )
      .filter((req) => isLeaveRequestApproved(req))
      .filter((req) => isOverLapping(req));
  };

  const calculateShortLeaveAmount = (leaveObj) => {
    const startTime_ = moment(leaveObj.startTime, "HH:mm:ss a");
    const endTime_ = moment(leaveObj.endTime, "HH:mm:ss a");
    const duration = moment.duration(endTime_.diff(startTime_));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes() % 60);

    return minutes === 0
      ? `${hours} ${t("hours")} `
      : `${hours} ${t("hours")} : ${minutes} ${t("minutes")}  `;
  };

  const getAmount = () => {
    if (isShortLeave) {
      return calculateShortLeaveAmount(leave);
    }

    return `${leave.amount} ${t("days")}`;
  };

  const [amount, setAmount] = useState(getAmount());

  const closeModal = () => {
    setOpenEdit(false);
    setError("");
    setLeave(_leave);
  };

  const onSubmit = () => {
    if (leave.reason === "") {
      setError(t("reasonError"));
    } else {
      if (leave.kind !== "SHORT") {
        const { startTime, endTime, amount, leaveDebit, ...rest } = leave;
        const startDate = moment(rest.date, "yyyy-MM-DD");
        const endDate = moment(rest.endDate, "yyyy-MM-DD");

        const duration = moment.duration(endDate.diff(startDate));
        const totalDays = parseInt(duration.asDays());
        if (user.isEmployee) {
          console.log("user", user.isEmployee);
          amendLeaveRequest(
            {
              ...rest,
              employeeId: request.employee.id,
              id: request.id,
            },
            () => {
              setOpenEdit(false);
              setAmount(`${totalDays} ${t("days")}`);
              setError("");
            },
          );
        } else {
          updateLeaveRequest(
            {
              ...rest,
              employeeId: request.employee.id,
              id: request.id,
            },
            () => {
              setOpenEdit(false);
              setAmount(`${totalDays} ${t("days")}`);
              setError("");
            },
          );
        }
      } else {
        const { startTime, endTime, requestSalaryBeforeLeave, ...rest } = leave;
        // Check if startTime and endTime are Moment objects before calling format
        if (moment.isMoment(startTime) && moment.isMoment(endTime)) {
          if (moment(endTime).isBefore(moment(startTime))) {
            setError(t("timeError"));
          } else {
            if (user.isEmployee) {
              console.log("user", user.isEmployee);
              amendLeaveRequest(
                {
                  ...rest,
                  id: request.id,
                  employeeId: request.employee.id,
                  startTime: startTime.format("HH:mm:ss"),
                  endTime: endTime.format("HH:mm:ss"),
                },
                () => {
                  setOpenEdit(false);
                  setAmount(calculateShortLeaveAmount(rest));
                  setError("");
                },
              );
            } else {
              updateLeaveRequest(
                {
                  ...rest,
                  id: request.id,
                  employeeId: request.employee.id,
                  startTime: startTime.format("HH:mm:ss"),
                  endTime: endTime.format("HH:mm:ss"),
                },
                () => {
                  setOpenEdit(false);
                  setAmount(calculateShortLeaveAmount(rest));
                  setError("");
                },
              );
            }
          }
        } else {
          // Handle the case where startTime or endTime is not a Moment object
          setError("Invalid start time or end time");
        }
      }
    }
  };
  const isCancelAllowed =
    request.status === "APPROVED" && (hasHrAccess || user.isCompanyOwner);

  return (
    <>
      <Section>
        <Box clone bgcolor="white" borderRadius={10} marginY={10}>
          <Grid container spacing={3}>
            <Grid
              item
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              md={12}
            >
              <Grid item>
                <Typography variant="h3" color="primary">
                  {t("leaveDetails")}
                </Typography>
              </Grid>
              {moment().isBefore(moment(leave.date)) && (
                <Grid item>
                  <IconButton
                    onClick={() => {
                      setOpenEdit(true);
                    }}
                  >
                    <Edit color="primary" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Field label={t("reason")} value={leave.reason} />
            <Field
              label={t("kind")}
              value={isEnglish ? kind.name : kind.nameAr}
            />
            <Field
              label={t("date")}
              value={moment(new Date(leave.date)).format("DD-MM-yyyy")}
            />
            {isShortLeave ? (
              <>
                <Field
                  label={t("startTime")}
                  value={moment(leave.startTime, "HH:mm:ss").format("hh:mm A")}
                />
                <Field
                  label={t("endTime")}
                  value={moment(leave.endTime, "HH:mm:ss").format("hh:mm A")}
                />
              </>
            ) : (
              <Field
                label={t("endDate")}
                value={moment(new Date(leave.endDate)).format("DD-MM-yyyy")}
              />
            )}

            <Field label={t("amount")} value={amount} />

            <Field label={t("balance")} value={`${getBalance()}`} />
            {(hasHrAccess || user.isCompanyOwner || hasVacationsAccess) && (
              <>
                {request.status === "PENDING" &&
                  (user.isCompanyOwner ||
                    myApproverStatus?.status === "PENDING") && (
                    <Grid item md={12} xs={12}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                      >
                        <Box clone marginRight={1}>
                          <Button
                            onClick={() => setOpen(true)}
                            variant="contained"
                            color="primary"
                          >
                            <Typography>{t("approve")} </Typography>
                          </Button>
                        </Box>
                        <Box clone marginRight={1}>
                          <Button
                            onClick={() => setOpenDecline(true)}
                            variant="contained"
                            color="secondary"
                          >
                            <Typography>{t("decline")} </Typography>
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                {isCancelAllowed && (
                  <Grid item md={12} xs={12}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                    >
                      <Box clone marginRight={1}>
                        <LoadingButton
                          onClick={() =>
                            updateLeaveRequest(
                              {
                                employeeId: request.employee.id,
                                id: request.id,
                                status: "DECLINED",
                              },
                              () => setOpen(false),
                            )
                          }
                          variant="contained"
                          color="secondary"
                          label={t("cancel")}
                          // loading={updateRequestLoading}
                        />
                      </Box>
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Box>
        <CompanyStructureModal
          open={open}
          onClose={() => setOpen(false)}
          fadeIn={open}
          title={t("approveLeave")}
        >
          <Box height={"auto"} maxHeight={"60vh"} margin={4}>
            {updateRequestLoading ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                {" "}
                <CircularProgress size={40} />
              </Box>
            ) : (
              <>
                <Grid item md={12} style={{ margin: 20 }}>
                  <MultilineInput
                    fullWidth
                    multiline
                    rows={2}
                    label="Notes"
                    onChange={(e) => setNotes(e.target.value)}
                    value={notes}
                    placeholder="optional"
                  />
                </Grid>
                <Box clone paddingY={4}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    {hasHrAccess ||
                    user.isCompanyOwner ||
                    (hasVacationsAccess && employeeWithHighestPriority) ? (
                      <>
                        <Grid item md={4}>
                          <LoadingButton
                            onClick={() => {
                              updateLeaveRequest(
                                {
                                  employeeId: request.employee.id,
                                  id: request.id,
                                  status: "APPROVED",
                                  isCounted: true,
                                  notes,
                                },
                                () => setOpen(false),
                              );
                            }}
                            variant="contained"
                            color="primary"
                            label={t("countFromEmployeeCredit")}
                            // loading={updateRequestLoading}
                          />
                        </Grid>

                        {leave.kind === "ANNUAL" &&
                          getBalance() < leave.amount && (
                            <Grid item md={4}>
                              <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  updateLeaveRequest(
                                    {
                                      employeeId: request.employee.id,
                                      id: request.id,
                                      status: "APPROVED",
                                      isCounted: true,
                                      isOverflowed: true,
                                      notes,
                                    },
                                    () => setOpen(false),
                                  )
                                }
                                // loading={updateRequestLoading}
                                label={t("noSalaryVacationApprove")}
                              />
                            </Grid>
                          )}

                        <Grid item md={4}>
                          <LoadingButton
                            onClick={() =>
                              updateLeaveRequest(
                                {
                                  employeeId: request.employee.id,
                                  id: request.id,
                                  status: "APPROVED",
                                  isCounted: false,
                                  notes,
                                },
                                () => setOpen(false),
                              )
                            }
                            variant="contained"
                            color="secondary"
                            // loading={updateRequestLoading}
                            label={t("dontCountFromEmployeeCredit")}
                          />
                        </Grid>
                      </>
                    ) : (
                      hasVacationsAccess &&
                      !employeeWithHighestPriority && (
                        <>
                          <Grid item md={12}>
                            <Box
                              paddingY={4}
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                            >
                              <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  updateLeaveRequest(
                                    {
                                      employeeId: request.employee.id,
                                      id: request.id,
                                      status: "APPROVED",
                                      notes,
                                    },
                                    () => setOpen(false),
                                  )
                                }
                                // loading={updateRequestLoading}
                                label={t("confirm")}
                              />

                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpen(false)}
                              >
                                <Typography>{t("cancel")} </Typography>
                              </Button>
                            </Box>
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>
                </Box>
              </>
            )}
          </Box>
        </CompanyStructureModal>
        <CompanyStructureModal
          open={openDecline}
          onClose={() => setOpenDecline(false)}
          fadeIn={openDecline}
          title={t("approveUpdate")}
        >
          <Grid container direction="column">
            <Grid item md={12} style={{ margin: 10 }}>
              <MultilineInput
                fullWidth
                multiline
                rows={2}
                label={t("notes")}
                placeholder="optional"
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
              />
            </Grid>
            <Grid item md={12} style={{ margin: 10 }}>
              <Box
                paddingY={4}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    updateLeaveRequest(
                      {
                        employeeId: request.employee.id,
                        id: request.id,
                        status: "DECLINED",
                        notes: notes,
                      },
                      () => setOpenDecline(false),
                    )
                  }
                >
                  <Typography>{t("yes")} </Typography>
                </Button>

                <Button
                  onClick={() => setOpenDecline(false)}
                  variant="contained"
                  color="secondary"
                >
                  <Typography>{t("no")} </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CompanyStructureModal>
        <CompanyStructureModal
          open={openEdit}
          onClose={closeModal}
          fadeIn={openEdit}
          title={t("editLeave")}
        >
          <Box height={"auto"} maxHeight={"60vh"} margin={2}>
            {updateRequestLoading ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                {" "}
                <CircularProgress size={40} />
              </Box>
            ) : (
              <>
                <Grid container spacing={5} justifyContent="center">
                  {error && (
                    <Grid item md={8} xs={12}>
                      <Alert onClick={() => setError("")} severity="error">
                        {error}
                      </Alert>
                    </Grid>
                  )}
                  <Grid item md={6} xs={6}>
                    <FormInputField
                      isRequired
                      label={t("reason")}
                      name="reason"
                      type="text"
                      onChange={(e) => {
                        setLeave({ ...leave, reason: e.target.value });
                      }}
                      value={leave.reason}
                      id="reason"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <FormSelectInput
                      isRequired
                      label={t("leaveKind")}
                      name="kind"
                      selectOptions={leavesKind
                        .filter((item) =>
                          companyVacationTypes.length > 0
                            ? companyVacationTypes.includes(item.value) ||
                              item.value === "UNPAID"
                            : vacationTypes.includes(item.value) ||
                              item.value === "UNPAID",
                        )
                        .map((kind) => ({
                          label: isEnglish ? kind.name : kind.nameAr,
                          value: kind.value,
                        }))}
                      onChange={(e) => setLeave({ ...leave, kind: e })}
                      value={leave.kind}
                    />
                  </Grid>
                </Grid>
                {isShortLeave ? (
                  <>
                    <Grid item md={12} xs={6}>
                      <DatePickerField
                        label={t("date")}
                        name="date"
                        onChange={(e) =>
                          setLeave({
                            ...leave,
                            date: moment(e).format("yyyy-MM-DD"),
                            endDate: moment(e).format("yyyy-MM-DD"),
                          })
                        }
                        value={leave.date}
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <TimePickerField
                        label={t("startTime")}
                        name="startTime"
                        onChange={(e) => {
                          setLeave({
                            ...leave,
                            startTime: moment(e, "HH:mm:ss"),
                          });
                        }}
                        value={leave.startTime}
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <TimePickerField
                        label={t("endTime")}
                        name="endTime"
                        onChange={(e) => {
                          setLeave({
                            ...leave,
                            endTime: moment(e, "HH:mm:ss"),
                          });
                        }}
                        value={leave.endTime}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid
                    container
                    spacing={5}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Grid item md={6} xs={6}>
                      {/* <DateRangeInput
                      value={[new Date(leave.date), new Date(leave.endDate)]}
                      onChange={(e) =>
                        setLeave({
                          ...leave,
                          date: moment(e[0]).format("yyyy-MM-DD"),
                          endDate: moment(e[1]).format("yyyy-MM-DD"),
                        })
                      }
                    /> */}
                      <DatePickerField
                        label={t("date")}
                        name="date"
                        onChange={(e) =>
                          setLeave({
                            ...leave,
                            date: moment(e).format("yyyy-MM-DD"),
                          })
                        }
                        value={leave.date ? moment(leave.date) : null}
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <DatePickerField
                        label={t("endDate")}
                        name="endDate"
                        onChange={(e) =>
                          setLeave({
                            ...leave,
                            endDate: moment(e).format("yyyy-MM-DD"),
                          })
                        }
                        value={leave.endDate ? moment(leave.endDate) : null}
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  style={{ marginTop: "25px", marginBottom: "25px" }}
                >
                  <LeaveFile
                    value={leave.document}
                    setValue={(newDocument) =>
                      setLeave({ ...leave, document: newDocument })
                    }
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    <Box clone marginX={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          console.log("leave", leave);
                          onSubmit();
                        }}
                      >
                        <Typography>{t("confirm")}</Typography>
                      </Button>
                    </Box>
                    <Box clone marginX={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setOpenEdit(false)}
                      >
                        <Typography>{t("cancel")}</Typography>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
          </Box>
        </CompanyStructureModal>
      </Section>
      {(hasHrAccess || user.isCompanyOwner || hasVacationsAccess) && (
        <Section>
          <Box clone bgcolor="white" borderRadius={10} marginY={10}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <Typography variant="h3" color="primary">
                  {t("employeesOnVacationOnSameDates")}
                </Typography>
              </Grid>
              {getEmployeesOnVacation().length > 0 ? (
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {["Name", "Start Date", "End Date"].map((label) => (
                          <TableCell
                            style={{ backgroundColor: "white", border: "none" }}
                            key={label}
                          >
                            {label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getEmployeesOnVacation().map((req) => {
                        return (
                          <TableRow tabIndex={-1} key={req.id}>
                            <TableCell style={{ border: "none" }}>
                              {req.employee?.fullName}
                            </TableCell>
                            <TableCell style={{ border: "none" }}>
                              {moment(req.leaveRequest.date).format(
                                "DD-MM-yyyy",
                              )}
                            </TableCell>
                            <TableCell style={{ border: "none" }}>
                              {moment(req.leaveRequest.endDate).format(
                                "DD-MM-yyyy",
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Grid item md={12}>
                  <Typography color="primary" align="center">
                    {t("noRecords")}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        </Section>
      )}
    </>
  );
};

const mapStateToProps = ({ auth, employee, requests }) => ({
  user: auth.user,
  employeeProfile: employee.profile,
  updateRequestLoading: requests.leaveRequestLoading,
});

const mapDispatchToProps = {
  updateLeaveRequest,
  amendLeaveRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Leave);
